import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, onSnapshot, limit, setDoc, doc, deleteDoc,getDocs } from 'firebase/firestore';
import { db } from '../firebase/firestore';

const TopTen = () => {
  const [mineLeaderBoard, setMineLeaderBoard] = useState([]);

  // Fetch top 100 users from 'telegramUsers' to update leaderboard
  const fetchUsers = async () => {
    const q = query(collection(db, 'telegramUsers'), orderBy('miningTotal', 'desc'), limit(100));
    return new Promise((resolve) => {
      onSnapshot(q, (querySnapshot) => {
        resolve(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
    });
  };

  // Function to update the leaderboard (same as from AdminMineRanks)
  const addUsersToLeaderBoard = async () => {
    const users = await fetchUsers();
    const mineLeaderBoardSnapshot = await getDocs(query(collection(db, 'mineLeaderBoard'), orderBy('miningTotal', 'desc')));
    const currentMineLeaderBoard = mineLeaderBoardSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    let updated = false;

    for (const user of users) {
      const existingUser = currentMineLeaderBoard.find((lbUser) => lbUser.id === user.id);

      if (existingUser) {
        if (existingUser.miningTotal !== user.miningTotal) {
          await setDoc(doc(db, 'mineLeaderBoard', user.id), {
            id: user.id,
            username: user.username,
            miningTotal: user.miningTotal,
            fullName: user.fullName || user.username,
          });
          updated = true;
        }
      } else {
        if (currentMineLeaderBoard.length >= 100) {
          const lowestUser = currentMineLeaderBoard[currentMineLeaderBoard.length - 1];
          await deleteDoc(doc(db, 'mineLeaderBoard', lowestUser.id));
        }

        await setDoc(doc(db, 'mineLeaderBoard', user.id), {
          id: user.id,
          username: user.username,
          miningTotal: user.miningTotal,
          fullName: user.fullName || user.username,
        });
        updated = true;
      }
    }

    if (updated) {
      fetchMineLeaderBoard();
    }
  };

  // Fetch the leaderboard from Firestore in real-time
  const fetchMineLeaderBoard = () => {
    const q = query(collection(db, 'mineLeaderBoard'), orderBy('miningTotal', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setMineLeaderBoard(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    // Clean up the listener on unmount
    return unsubscribe;
  };

  // Call the leaderboard update function when component is mounted
  useEffect(() => {
    const updateLeaderboardOnVisit = async () => {
      await addUsersToLeaderBoard();
    };

    updateLeaderboardOnVisit();

    const unsubscribe = fetchMineLeaderBoard();
    return () => unsubscribe(); // Clean up listener on component unmount
  }, []); // Empty dependency array ensures it runs once on component mount

  const getInitials = (username) => {
    const nameParts = username.split(' ');
    return nameParts[0].charAt(0).toUpperCase() + nameParts[0].charAt(1).toUpperCase();
  };

  const getRandomColor = () => {
    const colors = ['bg-red-500', 'bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Custom images for top 5 users
  const rankImages = ['/1st.webp', '/2nd.webp', '/3rd.webp', '/4th.webp', '/5th.webp'];

  const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return '';
    }

    const formattedNumber = number.toFixed(8);
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  return (
    <>
      <div className="w-full flex flex-col space-y-3 pt-3">
        {mineLeaderBoard
          .slice(0, 10)
          .sort((a, b) => b.miningTotal - a.miningTotal) // Sort users by balance in descending order
          .map((user, index) => (
            <div key={user.id} className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-3">
              <div className="w-fit">
                <div className={`flex items-center justify-center h-[42px] w-[42px] rounded-full p-1 ${getRandomColor()}`}>
                  {user.photo_url ? (
                    <img src={user.photo_url} alt={user.username} className="w-full h-full object-cover rounded-full" />
                  ) : (
                    <span className="font-semibold text-[14px]">{getInitials(user.username)}</span>
                  )}
                </div>
              </div>
              <div className="flex h-full flex-1 flex-col justify-center relative">
                <div className="flex w-full flex-col justify-between h-full space-y-[2px]">
                  <h1 className="text-[14px] text-nowrap line-clamp-1 font-medium">{user.username}</h1>
                  <span className="flex items-center gap-1 flex-1 text-[12px]">
                    <img src="/stars2.svg" alt="star" className="w-[10px]" />
                    <span className="text-[12px] text-nowrap font-medium">{formatNumber(user.miningTotal)}</span>
                  </span>
                </div>
              </div>
              <div className="w-fit flex items-center justify-end flex-wrap text-[14px] relative px-4">
                {index < 5 ? (
                  <img src={rankImages[index]} alt={`Rank ${index + 1}`} className="w-[24px] h-auto" />
                ) : (
                  <button className="font-semibold ease-in duration-200">#{index + 1}</button>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default TopTen;
