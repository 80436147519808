//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyARmjZRNQ-WvGmyaMwtj5SaXK16ukhUZTM",
  authDomain: "gamausdt.firebaseapp.com",
  projectId: "gamausdt",
  storageBucket: "gamausdt.appspot.com",
  messagingSenderId: "621840263116",
  appId: "1:621840263116:web:0f0f0d610747c2647f5f69",
  measurementId: "",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
