import React, { useEffect, useState } from 'react';
import { PiCheckCircle } from "react-icons/pi";
import { useUser } from '../context/userContext';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firestore';

const NewUser = ({ setChecker }) => {
  const [currentCheck, setCurrentCheck] = useState(0);
  const [visibleChecks, setVisibleChecks] = useState(0);
  const [completedChecks, setCompletedChecks] = useState([]);
  const { id, premium, premiumReward, setCheckinRewards, setShowClaimModal, setLastCheckIn, setCheckInDays, setPremiumReward, setBalance } = useUser();
  const [awardedPoint, setAwardedPoint] = useState(0);
  const premiumPoints = 0.1;
  const checkinPoint = 0.008;

  // Function to calculate Telegram age in years
  const calculateTelegramAge = (authDate) => {
    const now = new Date();
    const joinDate = new Date(authDate * 1000); // Convert Unix timestamp to Date
    const ageInYears = now.getFullYear() - joinDate.getFullYear();
    return Math.max(ageInYears, 0); // Ensure the age is non-negative
  };

  // Rewards based on Telegram age for premium users
  const getPremiumRewardByAge = (age) => {
    const rewards = [
      0.1, // 0 years
      0.2, // 1 year
      0.25, // 2 years
      0.5,  // 3 years
      0.75, // 4 years
      1.0,  // 5 years
      1.5,  // 6 years
      2.0,  // 7 years
      2.5,  // 8 years
      3.0,  // 9 years
      4.0,  // 10 years
      5.0   // 11+ years
    ];
    return rewards[Math.min(age, rewards.length - 1)];
  };

  // Rewards based on Telegram age for regular users
  const getRegularRewardByAge = (age) => {
    const rewards = [
      0.05, // 0 years
      0.15, // 1 year
      0.35, // 2 years
      0.55, // 3 years
      0.85, // 4 years
      1.1,  // 5 years
      1.4,  // 6 years
      1.7,  // 7 years
      2.1,  // 8 years
      2.4,  // 9 years
      2.7,  // 10 years
      3.0   // 11+ years
    ];
    return rewards[Math.min(age, rewards.length - 1)];
  };

  const awardPointsPrem = async (authDate) => {
    const telegramAge = calculateTelegramAge(authDate); // Calculate Telegram age
    const premiumRewardAmount = getPremiumRewardByAge(telegramAge); // Get reward based on age

    // Calculate the new balance
    const newBalance = premiumRewardAmount + checkinPoint + premiumPoints;

    try {
      const now = new Date();
      const userRef = doc(db, 'telegramUsers', id.toString());
      await updateDoc(userRef, {
        balance: newBalance,
        yearsReward: premiumRewardAmount,
        checkinRewards: checkinPoint,
        premiumReward: premiumReward + premiumPoints,
        checkInDays: [1],
        lastCheckIn: Timestamp.fromDate(now),
      });

      setBalance(newBalance);
      setAwardedPoint(premiumRewardAmount);
      setCheckinRewards(checkinPoint);
      setPremiumReward(premiumPoints);
      setLastCheckIn(now);
      setCheckInDays([1]);
      setShowClaimModal(false);
    } catch (error) {
      console.error('Error updating user points:', error);
    }
  };

  const awardPointsNotPrem = async (authDate) => {
    const telegramAge = calculateTelegramAge(authDate); // Calculate Telegram age
    const regularRewardAmount = getRegularRewardByAge(telegramAge); // Get reward based on age

    // Calculate the new balance
    const newBalance = regularRewardAmount + checkinPoint;

    try {
      const now = new Date();
      const userRef = doc(db, 'telegramUsers', id.toString());
      await updateDoc(userRef, {
        balance: newBalance,
        yearsReward: regularRewardAmount,
        checkinRewards: checkinPoint,
        checkInDays: [1],
        lastCheckIn: Timestamp.fromDate(now),
      });

      setBalance(newBalance);
      setAwardedPoint(regularRewardAmount);
      setCheckinRewards(checkinPoint);
      setLastCheckIn(now);
      setCheckInDays([1]);
      setShowClaimModal(false);
    } catch (error) {
      console.error('Error updating user points:', error);
    }
  };

  useEffect(() => {
    if (id) {
      const authDate = new Date().getTime() / 1000; // Assuming auth_date is provided (replace this with actual authDate)
      if (premium) {
        awardPointsPrem(authDate); // For premium users
      } else {
        awardPointsNotPrem(authDate); // For regular users
      }
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (visibleChecks < 4) {
      const timer = setTimeout(() => {
        setVisibleChecks(visibleChecks + 1);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [visibleChecks]);

  useEffect(() => {
    if (currentCheck < 4 && currentCheck < visibleChecks) {
      const timer = setTimeout(() => {
        setCurrentCheck(currentCheck + 1);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [currentCheck, visibleChecks]);

  const handleTransitionEnd = (index) => {
    setCompletedChecks((prev) => [...prev, index]);
  };

  const allChecksCompleted = completedChecks.length === 4;

  const handleContinue = () => {
    // Close the checker or modal when continue is clicked
    setChecker(false); // This will close the modal and return to the app or previous screen
  };

  const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return '';
    }
  
    // Ensure 8 decimal places for all numbers
    const formattedNumber = number.toFixed(8);
  
    // Add commas only to the integer part of the number (before the decimal point)
    const parts = formattedNumber.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    // Join the integer part and the decimal part back together
    return parts.join(".");
  };

  return (
    <>
      <div className='w-full h-full relative pb-24 flex items-center justify-center flex-col space-y-20'>
        <div className='font-bold checking w-full absolute top-8 text-center text-[#fff]'>
          Checking your account
        </div>

        <div className='flex flex-col w-full justify-start items-center px-1 space-y-16'>
          <div className="flex flex-col items-start !mt-[-100px] space-y-8 w-full">
            {['Account Age Verified', 'Activity Level Analyzed', 'Telegram Premium Checked', 'OG Status Confirmed'].map((check, index) => (
              <div key={index} className={`flex flex-col items-center gap-2 w-full transition-opacity duration-500 ${index < visibleChecks ? 'opacity-100' : 'opacity-0'}`}>
                <div className='w-full flex justify-between items-center'>
                  <div className='text-[16px] font-semibold'>{check}</div>
                  <PiCheckCircle size={20} className={`${completedChecks.includes(index) ? 'text-accent' : 'text-white'}`} />
                </div>
                <div className="w-full h-2 bg-gray-300 rounded-[2px] relative overflow-hidden">
                  <div
                    className={`h-full bg-btn ${currentCheck > index ? 'w-full' : 'w-0'} transition-all duration-[2000ms]`}
                    onTransitionEnd={() => handleTransitionEnd(index)}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          {allChecksCompleted && (
            <button onClick={handleContinue} className={`w-full py-3 rounded-[8px] absolute bottom-10 font-semibold px-3 items-center justify-center text-center bg-btn`}>
              Continue
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default NewUser;
